<template>
  <nav class="fixed left-0 top-20 w-full bg-white-500 lg:hidden z-[50]">
    <ul
      class="h-[calc(100vh-80px)] flex flex-col justify-between items-center py-20 label-2 text-neutral-500"
    >
      <li>
        <LinkTab
          label="Psikolojik Testler"
          to="/testler/psikolojik-testler"
          @click="$emit('hamburgerClick')"
        />
      </li>
      <li>
        <LinkTab
          :label="`${companyName} Blog`"
          to="/blog"
          @click="$emit('hamburgerClick')"
        />
      </li>
      <li>
        <LinkTab
          label="Şirketler için"
          to="/sirketler-icin"
          @click="$emit('hamburgerClick')"
        />
      </li>
      <li>
        <LinkTab
          label="Uzmanlarımız"
          to="/uzmanlarimiz"
          @click="$emit('hamburgerClick')"
        />
      </li>
      <li>
        <LinkTab
          label="İletişim"
          to="/iletisim"
          @click="$emit('hamburgerClick')"
        />
      </li>
      <!-- <li>
               <LinkTab label="S.S.S" to="/sikca-sorulan-sorular" @click="$emit('hamburgerClick')" />
      </li> -->
      <li class="flex gap-3 justify-center">
        <Button
          class="w-32"
          type="secondary"
          size="small"
          label="Online Görüşme"
          @click="navigateToMeeting"
        />
        <Button
          class="w-32"
          type="secondary"
          size="small"
          label="Giriş Yapın"
          @click="navigateToLogin"
        />
      </li>
      <li class="mt-4">
        <div class="flex flex-row justify-evenly w-40 -ml-2">
          <a
            :href="companyInstagram"
            target="_blank"
            class="text-neutral-600 text-2xl antialiased drop-shadow icon-ig"
            :aria-label="`${companyName} Instagram`"
          />
          <a
            :href="companyLinkedin"
            class="text-neutral-600 text-2xl antialiased drop-shadow icon-linkedin"
            target="_blank"
            :aria-label="`${companyName} Linkedin`"
          />
          <a
            :href="companyYoutube"
            class="text-neutral-600 text-2xl antialiased drop-shadow icon-youtube"
            target="_blank"
            :aria-label="`${companyName} Youtube`"
          />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import Tracking from "~/utils/tracking";
import { useMeetingStore } from "~/store/meeting";
import {
  COMPANY_NAME,
  COMPANY_INSTAGRAM,
  COMPANY_LINKEDIN,
  COMPANY_YOUTUBE,
} from "~/constants.js";

export default {
  name: "MobileMenu",
  emits: ["hamburgerClick"],
  data() {
    return {
      companyName: COMPANY_NAME,
      companyInstagram: COMPANY_INSTAGRAM,
      companyLinkedin: COMPANY_LINKEDIN,
      companyYoutube: COMPANY_YOUTUBE,
    };
  },
  setup() {
    const tracking = Tracking.getInstance();

    return {
      tracking,
    };
  },

  methods: {
    navigateToMeeting() {
      if (!this.$isMobile()) {
        const meetingStore = useMeetingStore();
        meetingStore.updateMeetingSource("Web Page");
      }
      this.tracking.landingPage("Online Meeting Clicked", {
        Source: this.$router.currentRoute.value.path,
      });
      navigateTo({ path: routes.onlineMeeting.path });
      this.$emit("hamburgerClick");
    },
    navigateToLogin() {
      this.tracking.landingPage("Log In Clicked", {
        Source: this.$router.currentRoute.value.path,
      });
      navigateTo({ path: routes.journey.path });
      this.$emit("hamburgerClick");
    },
  },
};
</script>
