<script setup lang="ts">
import Tracking from "~/utils/tracking";
import { useRouter } from "vue-router";
import { useMeetingStore } from "~/store/meeting";

const tracking = Tracking.getInstance();
const router = useRouter();

const navigateToMeeting = () => {
  const meetingStore = useMeetingStore();
  meetingStore.updateMeetingSource("Web Page");
  tracking.landingPage("Online Meeting Clicked", {
    Source: router.currentRoute.value.path,
  });
  navigateTo({ path: "/app/online-gorusme" });
};

const navigateToLogin = () => {
  tracking.landingPage("Log In Clicked", {
    Source: router.currentRoute.value.path,
  });
  navigateTo({ path: routes.journey.path });
};
</script>

<template>
  <nav v-if="$viewport.isGreaterThan('md')" class="flex items-center gap-10">
    <LinkTab label="Psikolojik Testler" to="/testler/psikolojik-testler" />
    <LinkTab label="Heltia Blog" to="/blog" />
    <LinkTab label="Şirketler için" to="/sirketler-icin" />
    <LinkTab label="Uzmanlarımız" to="/uzmanlarimiz" />
    <div class="flex gap-3">
      <Button
        label="Online Görüşme"
        type="secondary"
        size="small"
        class="w-36"
        @click="navigateToMeeting"
      />
      <Button
        label="Giriş Yapın"
        type="secondary"
        size="small"
        class="w-36"
        @click="navigateToLogin"
      />
    </div>
  </nav>
</template>
