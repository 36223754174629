<template>
  <header class="bg-white-500 w-full fixed top-0 z-50 lg:relative">
    <div class="col-def h-[80px] lg:h-[100px]">
      <div id="logo" class="flex items-center col-span-1 lg:col-span-2">
        <NuxtLink to="/">
          <Img
            :src="`${cdn}/heltia-logo.webp`"
            width="110"
            height="34"
            :alt="`${companyName} Logo`"
          />
        </NuxtLink>
      </div>
      <div
        id="nav"
        class="col-span-3 lg:col-span-10 flex items-center justify-end"
      >
        <div
          v-if="$viewport.isGreaterThan('md')"
          id="menu"
          class="w-full flex flex-col justify-end"
        >
          <NavigationMenu class="flex justify-end" />
        </div>
        <SalusAppDownloadButton
          v-if="!$isDesktop || $viewport.isLessThan('lg')"
          size="small"
          label="Uzmanınızla eşleşin"
          class="mr-2 lg:mr-0 lg:ml-2 download-button"
          id="header-signup-flow"
        />
        <div
          v-if="$viewport.isLessThan('lg')"
          id="mobile-menu"
          class="flex items-center"
        >
          <ButtonHamburger />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";

export default {
  data() {
    const config = useRuntimeConfig();
    return {
      companyName: COMPANY_NAME,
      cdn: config.public.cdn,
    };
  },
};
</script>
