<template>
  <PartHeader
    @onHamburgerClick="switchMenu"
    :class="[isMenuDisplaying ? 'fixed w-full' : '', true && $props.class]"
  />
</template>
<script lang="ts">
export default {
  props: {
    class: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isMenuDisplaying: false,
    };
  },
  methods: {
    switchMenu() {
      this.isMenuDisplaying = !this.isMenuDisplaying;
    },
  },
};
</script>
