<template>
  <NavigationMobileMenu v-if="isOpen" @hamburgerClick="menuClick" />

  <button
    class="text-primary-400 text-2xl"
    :class="[isOpen ? 'icon-remove' : 'icon-menu']"
    @click="menuClick"
    aria-label="Menu"
    title="Menu"
  ></button>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    menuClick() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
