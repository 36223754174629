<template>
  <footer class="bg-white-500 pt-16 border-t border-neutral-200">
    <section class="col-def">
      <div class="col-span-4 lg:col-span-2 gap-0 h-fit order-4 lg:order-1">
        <NuxtLink
          to="/"
          class="col-span-2 lg:col-span-1 mb-9 h-full flex items-center lg:items-start lg:h-fit"
        >
          <Img
            :src="`${cdn}/heltia-logo.webp`"
            width="110"
            height="34"
            :alt="`${companyName} Logo`"
          />
        </NuxtLink>
        <div class="col-span-2 col-start-1">
          <span class="label-3">Akat, Menekşe Sk. No:63, 34335</span>
        </div>
        <div class="col-span-2 col-start-1 mb-2">
          <span class="label-3">Beşiktaş/İstanbul</span>
        </div>
        <div
          class="col-span-1 col-start-1 flex items-start justify-start lg:items-starth-fit"
        >
          <div class="w-[120px] flex flex-row justify-between lg:flex-none">
            <a
              :href="companyInstagram"
              target="_blank"
              class="text-neutral-600 text-2xl antialiased drop-shadow icon-ig"
              :aria-label="`${companyName} Instagram`"
            />
            <a
              :href="companyLinkedin"
              class="text-neutral-600 text-2xl antialiased drop-shadow icon-linkedin"
              target="_blank"
              :aria-label="`${companyName} Linkedin`"
            />
            <a
              :href="companyYoutube"
              class="text-neutral-600 text-2xl antialiased drop-shadow icon-youtube"
              target="_blank"
              :aria-label="`${companyName} Youtube`"
            />
          </div>
        </div>
      </div>
      <div class="col-span-4 grid grid-cols-4 h-fit order-1">
        <Body1 class="col-span-4 h-fit text-neutral-500 mb-4">
          {{ companyName }}
        </Body1>
        <div class="col-span-4 grid grid-cols-4">
          <ul class="footer-link-list">
            <li class="label-2">
              <NuxtLink to="/">Anasayfa</NuxtLink>
            </li>
            <li class="label-2">
              <NuxtLink
                :to="routes.onlineMeeting.path"
                @click="navigateToMeeting"
              >
                Online görüşme
              </NuxtLink>
            </li>

            <li class="label-2">
              <NuxtLink to="/sirketler-icin">Şirketler için</NuxtLink>
            </li>

            <li class="label-2">
              <NuxtLink to="/uzmanlarimiz">Uzmanlarımız</NuxtLink>
            </li>

            <li class="label-2">
              <NuxtLink to="/hakkimizda">Hakkımızda</NuxtLink>
            </li>
            <li class="label-2">
              <NuxtLink to="/blog">{{ companyName }} Blog</NuxtLink>
            </li>
            <li class="label-2">
              <NuxtLink to="/iletisim">İletişim</NuxtLink>
            </li>

            <!-- <li class="label-2">
              <NuxtLink to="/">Hakkımızda</NuxtLink>
            </li> -->

            <li class="label-2">
              <NuxtLink to="/sikca-sorulan-sorular">S.S.S.</NuxtLink>
            </li>

            <li class="label-2">
              <NuxtLink to="/belge/kullanici-aydinlatma-metni"
                >Kullanıcı Aydınlatma Metni</NuxtLink
              >
            </li>

            <li class="label-2">
              <NuxtLink to="/belge/bilgi-guvenligi-genel-politikasi"
                >Bilgi güvenliği genel politikası</NuxtLink
              >
            </li>
          </ul>
          <ul class="footer-link-list">
            <li class="label-2">
              <NuxtLink to="/belge/danisman-aydinlatma-metni"
                >Danışman Aydınlatma Metni</NuxtLink
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        class="col-span-4 lg:col-span-3 grid grid-cols-4 order-2 content-start"
      >
        <Body1 class="col-span-4 text-neutral-500 mb-4">
          Popüler blog içerikleri
        </Body1>
        <ul class="blog-link-list">
          <li class="label-2" v-for="(blog, index) in blogs" :key="index">
            <NuxtLink :to="blog.url">
              {{ blog.title }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div
        class="col-span-4 lg:col-span-3 grid grid-cols-4 order-3 content-start"
      >
        <Body1 class="col-span-4 text-neutral-500 mb-4">
          Psikolojik testler
        </Body1>
        <div class="col-span-4 grid grid-cols-4 gap-x-5">
          <ul class="blog-link-list">
            <li class="label-2" v-for="(test, index) in tests" :key="index">
              <NuxtLink :to="test.url">
                {{ test.title }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="copyright" class="col-span-12 text-center mt-12 mb-8">
      <Body1 class="text-neutral-400"
        >&copy; {{ companyName }} {{ new Date().getFullYear() }}</Body1
      >
    </section>
  </footer>
</template>
<style scoped>
.footer-link-list,
.blog-link-list {
  @apply col-span-4 grid grid-cols-4 gap-6;
}
.footer-link-list li {
  @apply col-span-2 font-semibold;
}
.blog-link-list li {
  @apply col-span-4 font-semibold;
}
</style>
<script>
import Tracking from "~/utils/tracking";
import { useMeetingStore } from "~/store/meeting";
import {
  COMPANY_NAME,
  COMPANY_INSTAGRAM,
  COMPANY_LINKEDIN,
  COMPANY_YOUTUBE,
} from "~/constants.js";

export default {
  data() {
    const config = useRuntimeConfig();

    const blogs = [
      {
        title:
          "Psikolojik uyuşma ve karıncalanma nasıl geçer? [5 Etkili Yöntem]",
        url: "/blog/psikolojik-uyusma-ve-karincalanma",
      },
      {
        title: "Araba Kullanma Korkusu Nasıl Yenilir? (8 Farklı Yöntem)",
        url: "/blog/araba-kullanma-korkusu",
      },
      {
        title: "Retroaktif Kıskançlık Nedir, Nasıl Geçer? (6 Etkili İpucu)",
        url: "/blog/retroaktif-kiskanclik",
      },
      {
        title: "Histrionik Kişilik Bozukluğu Olanlara Nasıl Davranmalı?",
        url: "/blog/histrionik-kisilik-bozuklugu-olanlara-nasil-davranmali",
      },
    ];

    const tests = [
      {
        title: "Depresyon Testi",
        url: "/testler/depresyon-testi/664525ba2e0933aad8355b20",
      },
      {
        title: "Anksiyete Testi",
        url: "/testler/anksiyete-testi/664525ba2e0933aad8355b23",
      },
      {
        title: "Motivasyon Ölçeği",
        url: "/testler/motivasyon-olcegi/664525ba2e0933aad8355b21",
      },
      {
        title: "Obsesif Kompulsif Bozukluk (OKB) Testi",
        url: "/testler/obsesif-kompulsif-bozukluk-testi/664525ba2e0933aad8355b22",
      },
    ];
    return {
      companyName: COMPANY_NAME,
      companyInstagram: COMPANY_INSTAGRAM,
      companyLinkedin: COMPANY_LINKEDIN,
      companyYoutube: COMPANY_YOUTUBE,
      cdn: config.public.cdn,
      blogs,
      tests,
    };
  },
  methods: {
    navigateToMeeting() {
      const meetingStore = useMeetingStore();
      meetingStore?.updateMeetingSource("Web Page");

      const tracking = Tracking.getInstance();
      tracking?.landingPage("Online Meeting Clicked", {
        Source: router.currentRoute.value.path,
      });
    },
  },
};
</script>
